import React from "react";
import Carousel from "nuka-carousel";
import { Image } from "semantic-ui-react";
import djpic1 from "../../images/djpic1.jpeg";
import djpic2 from "../../images/djpic2.jpeg";
import dj1 from "../../images/imagebucket2/20171008_182726.jpg";
import dj2 from "../../images/imagebucket2/IMG_0016.jpg";
import dj3 from "../../images/imagebucket2/IMG_0272.jpg";
import dj4 from "../../images/imagebucket2/IMG_0376.jpg";
import dj5 from "../../images/imagebucket2/IMG_0513.jpg";
import dj6 from "../../images/imagebucket2/IMG_0542.jpg";
import dj7 from "../../images/imagebucket2/IMG_0548.jpg";
import dj8 from "../../images/imagebucket2/IMG_0568.jpg";
import dj9 from "../../images/imagebucket2/IMG_0622.jpg";
import dj10 from "../../images/imagebucket2/IMG_0703.jpg";
import dj11 from "../../images/imagebucket2/IMG_1719.jpg";
import dj12 from "../../images/imagebucket2/IMG_2666.jpg";
import dj13 from "../../images/imagebucket2/IMG_2667.jpg";
import dj14 from "../../images/imagebucket2/IMG_3729.jpg";
import dj15 from "../../images/imagebucket2/IMG_3739.jpg";
import dj16 from "../../images/imagebucket2/IMG_3746.jpg";
import dj17 from "../../images/imagebucket2/IMG_3799.jpg";
import dj18 from "../../images/imagebucket2/IMG_3902.jpg";
import dj19 from "../../images/imagebucket2/IMG_20161011_120722.jpg";
import dj20 from "../../images/imagebucket2/IMG_20170408_213534_499.jpg";
import dj21 from "../../images/imagebucket2/IMG_20170422_164235_622.jpg";
import dj22 from "../../images/imagebucket2/IMG_20170723_131619_028.jpg";
//import djmixer from "../../images/djmixer.jpg";

export default class extends React.Component {
  render() {
    return (
      <Carousel
        autoplay
        wrapAround
        dragging
        //heightMode="max"
        transitionMode="fade"
        //speed={2000}
        withoutControls
        swiping
        // renderCenterLeftControls={({ previousSlide }) => null}
        // renderCenterRightControls={({ nextSlide }) => null}
      >
        <Image src={djpic2} fluid rounded className="carousel" />
        <Image src={djpic1} fluid rounded className="carousel" />
        <Image src={dj1} fluid rounded className="carousel" />
        <Image src={dj2} fluid rounded className="carousel" />
        <Image src={dj3} fluid rounded className="carousel" />
        <Image src={dj4} fluid rounded className="carousel" />
        <Image src={dj5} fluid rounded className="carousel" />
        <Image src={dj6} fluid rounded className="carousel" />
        <Image src={dj7} fluid rounded className="carousel" />
        <Image src={dj8} fluid rounded className="carousel" />
        <Image src={dj9} fluid rounded className="carousel" />
        <Image src={dj10} fluid rounded className="carousel" />
        <Image src={dj11} fluid rounded className="carousel" />
        <Image src={dj12} fluid rounded className="carousel" />
        <Image src={dj13} fluid rounded className="carousel" />
        <Image src={dj14} fluid rounded className="carousel" />
        <Image src={dj15} fluid rounded className="carousel" />
        <Image src={dj16} fluid rounded className="carousel" />
        <Image src={dj17} fluid rounded className="carousel" />
        <Image src={dj18} fluid rounded className="carousel" />
        <Image src={dj19} fluid rounded className="carousel" />
        <Image src={dj20} fluid rounded className="carousel" />
        <Image src={dj21} fluid rounded className="carousel" />
        <Image src={dj22} fluid rounded className="carousel" />
      </Carousel>
    );
  }
}
