import PropTypes from "prop-types";
import React, { Component } from "react";
import logo from "./images/LogoWithHeadGFX.png";
import InstagramEmbed from "react-instagram-embed";
import ImageCarousel from "./container/imageCarousel/imageCarousel";
//import EmailForm from "./container/form/emailForm";
import {
  Button,
  Container,
  //Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Embed
  //Sticky,
  //Form
} from "semantic-ui-react";

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container>
    {/* <Container text>
      <Header
        as="h1"
        content="DJ DMoney"
        inverted
        style={{
          fontSize: mobile ? "2em" : "4em",
          fontWeight: "normal",
          marginBottom: 0,
          marginTop: mobile ? "1.5em" : "3em"
        }}
      />
    </Container> */}
    {/* <Image src={djpic1} fluid rounded /> */}
    <Image
      src={logo}
      rounded
      centered
      size="big"
      className="App-logo"
      id="home"
    />
    <br />
    <br />
    <ImageCarousel />
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 700, padding: "1em 0em" }}
            vertical
          >
            <Menu
              fixed={fixed ? "top" : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item as="a" className="menuItem" href="#home">
                  DJ DMoney
                </Menu.Item>
                <Menu.Item as="a" href="#events">
                  Events
                </Menu.Item>
                <Menu.Item as="a" href="#services">
                  About
                </Menu.Item>
                <Menu.Item position="right">
                  <Button
                    circular
                    icon="instagram"
                    href="https://www.instagram.com/dmoneyindabuilding/"
                    color="instagram"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="facebook f"
                    href="https://www.facebook.com/djdmoneyindabuilding"
                    color="facebook"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="soundcloud"
                    href="https://soundcloud.com/dj-dmoney-in-da-building"
                    style={{ backgroundColor: "#F50", color: "white" }}
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="twitter"
                    href="https://twitter.com/dmoneybeats"
                    color="twitter"
                    target="_blank"
                  />
                </Menu.Item>

                <Menu.Item position="right">
                  <Button
                    size="huge"
                    color="red"
                    href="https://goo.gl/forms/4j9WwMm03NXLq2aO2"
                    target="_blank"
                  >
                    Book Now
                    <Icon name="right arrow" />
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>

            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as="a" href="#home" onClick={this.handleSidebarHide}>
            DJ DMoney
          </Menu.Item>
          <Menu.Item as="a" href="#events" onClick={this.handleSidebarHide}>
            Events
          </Menu.Item>
          <Menu.Item as="a" href="#services" onClick={this.handleSidebarHide}>
            About
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 350, padding: "1em 0em" }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item position="right">
                  <Button
                    circular
                    icon="instagram"
                    href="https://www.instagram.com/dmoneyindabuilding/"
                    color="instagram"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="facebook f"
                    href="https://www.facebook.com/djdmoneyindabuilding"
                    color="facebook"
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="soundcloud"
                    href="https://soundcloud.com/dj-dmoney-in-da-building"
                    style={{ backgroundColor: "#F50", color: "white" }}
                    target="_blank"
                  />
                  <Button
                    circular
                    icon="twitter"
                    href="https://twitter.com/dmoneybeats"
                    color="twitter"
                    target="_blank"
                  />
                </Menu.Item>
                <Menu.Item position="right">
                  <Button
                    size="huge"
                    color="red"
                    href="https://goo.gl/forms/4j9WwMm03NXLq2aO2"
                    target="_blank"
                    style={{ paddingRight: "1em" }}
                  >
                    Book Now
                    <Icon name="right arrow" />
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

//const handleChange = (e, { value }) => this.setState({ value });

// const options = [
//   { key: "m", text: "Male", value: "male" },
//   { key: "f", text: "Female", value: "female" }
// ];

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: "8em 0em" }} vertical className="middlebody">
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={8}>
            {/* <InstagramEmbed
              url="https://www.instagram.com/p/BuJompVgD7G/?utm_source=ig_web_copy_link"
              maxWidth={320}
              hideCaption={false}
              containerTagName="div"
              protocol=""
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            /> */}
          </Grid.Column>
          <Grid.Column width={8}>
            <Header
              as="h3"
              style={{ fontSize: "2em", color: "rgb(255, 85, 0)" }}
            >
              SoundCloud:
            </Header>
            <br />
            <Embed
              iframe={{
                width: "100%",
                height: "100%",
                scrolling: "no",
                frameborder: "no",
                src:
                  "https://w.soundcloud.com/player/?url=https://soundcloud.com/dj-dmoney-in-da-building/"
              }}
              source="youtube"
            />
            <br />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center" />
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment className="events" id="events">
      <Segment style={{ padding: "1em 0em" }} vertical inverted>
        <Container textAlign="center">
          <Header as="h1" style={{ fontSize: "5em", color: "white" }}>
            Events
          </Header>
        </Container>
      </Segment>

      <Segment style={{ padding: "0em" }} vertical>
        <Grid columns="equal" stackable>
          <Grid.Row>
            <Grid.Column
              style={{
                paddingBottom: "1em",
                paddingTop: "1em",
                display: "grid",
                alignItems: "center",
                justifyItems: "center"
              }}
            >
              <InstagramEmbed
                url="https://www.instagram.com/p/Bw-cY2rgTXl/?utm_source=ig_web_copy_link"
                maxWidth={320}
                hideCaption={true}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
              <InstagramEmbed
                url="https://www.instagram.com/p/BwvAjr4A0-V/?utm_source=ig_web_copy_link"
                maxWidth={320}
                hideCaption={true}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
            </Grid.Column>
            <Grid.Column
              style={{
                paddingBottom: "1em",
                paddingTop: "1em",
                display: "grid",
                alignItems: "center",
                justifyItems: "center"
              }}
            >
              <InstagramEmbed
                url="https://www.instagram.com/p/Bvk0JdIAJrT/?utm_source=ig_web_copy_link"
                maxWidth={320}
                hideCaption={true}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
              <InstagramEmbed
                url="https://www.instagram.com/p/BvDKLdjg_P-/?utm_source=ig_web_copy_link"
                maxWidth={320}
                hideCaption={true}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
            </Grid.Column>
            <Grid.Column
              style={{
                paddingBottom: "1em",
                paddingTop: "1em",
                display: "grid",
                alignItems: "center",
                justifyItems: "center"
              }}
            >
              <InstagramEmbed
                url="https://www.instagram.com/p/BvUHSPlgA1b/?utm_source=ig_web_copy_link"
                maxWidth={320}
                hideCaption={true}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
              <InstagramEmbed
                url="https://www.instagram.com/p/BpAM6rAl7Ob/?utm_source=ig_web_copy_link"
                maxWidth={320}
                hideCaption={true}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment>

    <Segment
      style={{
        padding: "1em 0em",
        display: "grid",
        alignItems: "center",
        justifyItems: "center"
      }}
      vertical
      inverted
    >
      <Button
        color="instagram"
        href="https://www.instagram.com/dmoneyindabuilding/"
        target="_blank"
      >
        <Icon name="instagram" />
        Instagram
      </Button>
      <br />
      <Button
        color="facebook"
        href="https://www.facebook.com/djdmoneyindabuilding"
        target="_blank"
      >
        <Icon name="facebook" /> Facebook
      </Button>
      <br />
      <Button
        href="https://soundcloud.com/dj-dmoney-in-da-building"
        target="_blank"
        style={{ backgroundColor: "#F50", color: "white" }}
      >
        <Icon name="soundcloud" /> SoundCloud
      </Button>
      <br />
      <Button
        color="twitter"
        href="https://twitter.com/dmoneybeats"
        target="_blank"
      >
        <Icon name="twitter" /> Twitter
      </Button>
    </Segment>
    <Segment
      inverted
      vertical
      style={{ padding: "3em 0em" }}
      className="services"
      id="services"
    >
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Services" />
              <List link inverted>
                <List.Item as="a">Wedding / Receptions</List.Item>
                <List.Item as="a">Birthday Party's / Family Reunion</List.Item>
                <List.Item as="a">Night Clubs / Dance Party's</List.Item>
                <List.Item as="a">Promotions Events</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
    <p>BeamerOne - 2019 - Web Designs</p>
  </ResponsiveContainer>
);

export default HomepageLayout;
